/** Carousels on kitchen pages and popups
 *  ------------------------------------------------------------------------------------------------
**/

/** KitchenCarousel
 *  ------------------------------------------------------------------------------------------------
**/

.KitchenCarousel {
  position: relative;
  overflow: hidden;
}

.KitchenCarousel__slide {
  transition:
    opacity var(--time-transition-slow) var(--ease-default),
    transform var(--time-transition-slow) var(--ease-default);
}


/** No and mobile Javascript styles
 *  ------------------------------------------------------------------------------------------------
 *  presented as blocks on small screens and if there's no js
**/
.KitchenInformation {

  & .SylSection__column {
    flex-basis: 100%;
  }

  & .SylSection__column--right {
    order: 1;
  }

  & .SylSection__column--left {
    order: 2;
    margin-top: var(--bsu-l);
  }

  & .KitchenCarousel {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  & .KitchenCarousel__slide {
    width: 50%;
    flex: 0 0 auto;

    & .Wrap {
      margin: 0;
    }
  }

  & .KitchenCarousel nav {
    display: none;
  }
}

@media (--edge-to-edge-ends) {

  .KitchenInformation .KitchenCarousel__slide .Wrap {
    padding: var(--bsu-s);
  }
}


@media (--column-break) {

  .js .KitchenInformation {

    & .SylSection__column {
      flex-basis: 50%;
    }

    & .SylSection__column--right {
      order: 2;
    }

    & .SylSection__column--left {
      order: 1;
      margin-top: 0;
    }

    & .KitchenCarousel {
      display: block;
    }

    & .KitchenCarousel__slide {
      width: 100%;
      flex: 0 0 auto;

      & .Wrap {
        padding: 0 var(--bsu);
      }
    }
  }

  /** Displaying slides when JS is enabled
  *  -----------------------------------------------------------------------------------------------
  **/
  .js .KitchenCarousel__slide {
    display: none;
    opacity: 0;

    /* transform: scale(1.05); */
  }

  .KitchenCarousel__slide.js--carousel__slide--trans-in {
    opacity: 1;

    /* transform: scale(1); */
  }

  .js .KitchenCarousel__slide.js--carousel__slide--active {
    display: block;
  }


  /** Nav elements
  *  -----------------------------------------------------------------------------------------------
  **/
  .KitchenCarousel nav {
    display: none;
  }

  .js .KitchenCarousel nav {
    display: block;

    & .js--carousel__nav__links {
      position: absolute;
      z-index: var(--z-section-nav);
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0;
    }

    & ul {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: rgb(255 255 255 / 0.3);
    }

    & li {
      display: block;
      padding: var(--bsu-s) var(--bsu-xs);
    }

    & button {
      display: block;
      background: transparent;
      color: var(--colour-primary);
      border: 2px solid;
      outline: 0;
      cursor: pointer;
      width: var(--bsu-s);
      height: var(--bsu-s);
      transition:
        color var(--time-transition) var(--ease-default),
        background-color var(--time-transition) var(--ease-default);
      transform: rotate(45deg);

      &:hover,
      &:focus {
        color: var(--colour-secondary);
      }
    }

    & .js--carousel__nav-link--active {
      background-color: currentColor;
    }

    /* next-prev */
    & .js--carousel__nav-link--prev {
      border-top: 0;
      border-right: 0;
    }

    & .js--carousel__nav-link--next {
      border-bottom: 0;
      border-left: 0;
    }
  }
}
