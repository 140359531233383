/** global or base site css
 *  ------------------------------------------------------------------------------------------------
 *  Here's where you can put all of your site specific code that should apply globally or isn't
 *  specific to any components etc.
**/

/* scale fonts based on screen size - included as an example - remove if you want! */
@import '_scale-fonts.css';

/** body defaults
 *  ------------------------------------------------------------------------------------------------
**/
html {
  scroll-behavior: smooth;
}

body {
  line-height: 1.5;
  font-family: var(--ff-body);
  color: var(--colour-primary);
  background-color: var(--colour-background-body);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scroll-behavior: smooth;

  /*
    Sometimes required for skinny fonts - particularly for light on dark text.
    Only applies on Mac OS.
  */
  /*
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
}

/** lock scrolling if popups are active
 *  ------------------------------------------------------------------------------------------------
**/
.js--has-popup {
  height: 100%;
  height: 100vh;
  overflow: hidden;
}

/** if we want main content not to float centrally:
 *  ------------------------------------------------------------------------------------------------
**/
/* .site-main {
  flex: 1;
} */
