/** FooterCTA
 *  ------------------------------------------------------------------------------------------------
**/

.FooterCTA {
  min-height: 0; /* doesn't need to be as tall as normal SylSections */
  padding: 0;
  overflow: hidden;

  & .SylSection__back {
    transition: transform var(--time-transition) var(--ease-default);
  }

  &::after {
    transition: opacity var(--time-transition) var(--ease-default);
  }

  &:hover {

    & .SylSection__back {
      transform: scale(1.1);
    }

    &::after {
      opacity: 0.6;
    }
  }

  & a {
    display: block;
    padding: var(--section-padding) 0;
    color: inherit;
  }

  & .HeaderText__post {
    font-size: var(--s2);
    margin-top: var(--bsu-s);
    color: var(--colour-secondary);
  }
}
