/** HomepageCarousel
 *  ------------------------------------------------------------------------------------------------
**/

.HomepageCarousel {
  position: relative;
  overflow: hidden;
  background-color: var(--colour-primary);
}

.HomepageCarousel__slide {
  overflow: hidden;
  transition: opacity var(--time-transition-slow) var(--ease-default);

  & .SylSection__back {
    transition: transform var(--time-transition-slow) var(--ease-default);
  }
}

@media (--edge-to-edge-ends) {

  .HomepageCarousel__slide {
    min-height: 60vh; /* taller than normal SylSections */
  }
}

/*
  overwrite top padding to be twice the normal syl-section if there's a floating header
  This is only for the first section if js is disabled. If js is enabled it should apply to all
  only after the menu starts floating
*/

@media (--menu-floats) {

  .no-js .with-transparent-header .HomepageCarousel__slide:first-child,
  .js .with-transparent-header .HomepageCarousel__slide {
    padding-top: calc(var(--section-padding) * 1.5);
  }
}

/** Displaying slides when JS is enabled
 *  ------------------------------------------------------------------------------------------------
**/
.js .HomepageCarousel__slide {
  display: none;
  opacity: 0;

  & .SylSection__back {
    transform: scale(1.2);
  }
}

.HomepageCarousel__slide.js--carousel__slide--trans-in {
  opacity: 1;

  & .SylSection__back {
    transform: scale(1);
  }
}

.js .HomepageCarousel__slide.js--carousel__slide--active {
  display: flex;
}


/** Nav elements
 *  ------------------------------------------------------------------------------------------------
**/
.HomepageCarousel nav {

  & .js--carousel__nav__links {
    position: absolute;
    z-index: var(--z-section-nav);
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 var(--bsu-s);
    background-color: rgb(0 0 0 / 0.6);
  }

  & ul {
    margin: 0 auto;
    max-width: var(--layout-max);
    display: flex;
    justify-content: space-around;
  }

  & li {
    display: block;
  }

  & button {
    background: transparent;
    color: var(--colour-invert);
    border: 0;
    outline: 0;
    font-family: var(--ff-heading);
    font-weight: normal;
    padding: var(--bsu-s);
    cursor: pointer;
    transition: color var(--time-transition) var(--ease-default);

    &:hover,
    &:focus {
      color: var(--colour-secondary);
    }
  }

  /* next-prev */
  & .js--carousel__nav-link--prev,
  & .js--carousel__nav-link--next {
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
    z-index: var(--z-section-nav);
    padding-left: 0;

    &::after {
      content: ' ';
      display: block;
      width: var(--bsu-s);
      height: var(--bsu);
      border-radius: 0 var(--bsu) var(--bsu) 0;
      background: var(--colour-invert);
      transition:
        background-color var(--time-transition) var(--ease-default),
        transform var(--time-transition) var(--ease-default);
    }

    &:hover,
    &:focus {

      &::after {
        background: var(--colour-secondary);
        transform: scale(1.5);
      }
    }
  }

  & .js--carousel__nav-link--next {
    left: auto;
    right: 0;
    padding-left: var(--bsu-s);
    padding-right: 0;

    &::after {
      border-radius: var(--bsu) 0 0 var(--bsu);
    }
  }
}


/** No Javascript styles
 *  ------------------------------------------------------------------------------------------------
 *  presented as a reel if there's no js
**/
.no-js .HomepageCarousel {
  display: flex;
  height: 60vh;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: var(--colour-primary) var(--colour-subtler);
  background: var(--colour-primary);
}

.no-js .HomepageCarousel::-webkit-scrollbar {
  height: var(--bsu-xs);
}

.no-js .HomepageCarousel::-webkit-scrollbar-track {
  background-color: var(--colour-background-subtle);
}

.no-js .HomepageCarousel::-webkit-scrollbar-thumb {
  background-color: var(--colour-background-subtle);
  background-image:
    linear-gradient(
      var(--colour-background-subtle) 0,
      var(--colour-background-subtle) calc(var(--bsu-xs) * 0.25),
      var(--colour-secondary) calc(var(--bsu-xs) * 0.25),
      var(--colour-secondary) calc(var(--bsu-xs) * 0.75),
      var(--colour-background-subtle) calc(var(--bsu-xs) * 0.75)
    );
}

.no-js .HomepageCarousel > * {
  flex: 0 0 90vw;
}


.no-js .HomepageCarousel > img {
  height: 100%;
  flex-basis: auto;
  width: auto;
}

.no-js .HomepageCarousel > * + * {
  margin-left: var(--bsu-s);
}

.no-js .HomepageCarousel nav {
  display: none;
}

/** Alternative no-js styling
 *  ------------------------------------------------------------------------------------------------
 *  presented as a block
**/
.no-js--alt .HomepageCarousel {
  display: flex;
  flex-wrap: wrap;
}

.no-js--alt .HomepageCarousel__slide:first-child {
  flex-basis: 100%;
  border-bottom: var(--bsu-s) solid var(--colour-background-body);
}

/* everything but the first child */
.no-js--alt .HomepageCarousel__slide:nth-child(1n+2) {
  min-height: 0;
  margin: 0 auto;
  flex: 1;
  flex-basis: 50%;
  border-bottom: var(--bsu-s) solid var(--colour-background-body);
}

/* every even numbered child after first */
.no-js--alt .HomepageCarousel__slide:nth-child(2n+2) {
  border-right: var(--bsu-xs) solid var(--colour-background-body);
}

/* every odd numbered slide after the first */
.no-js--alt .HomepageCarousel__slide:nth-child(2n+3) {
  border-left: var(--bsu-xs) solid var(--colour-background-body);
}

/* the last child if there are an uneven total number including the intro slide */
.no-js--alt .HomepageCarousel__slide:nth-child(2n+2):last-of-type {
  flex-basis: 100%;
  border: 0;
}
