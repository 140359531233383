
/** Material diamonds...
 *  ------------------------------------------------------------------------------------------------
 *  shown as a horizontal reel on smaller screens but get a unique two column layout
**/
.BespokeMaterials__materials .HorizontalReel,
.BespokeMaterials__materials .HorizontalReel:not(.HorizontalReel--constant) {
  background-color: transparent;
  padding: var(--bsu);

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    background-image: linear-gradient(transparent 0, transparent calc(var(--bsu-xs) * 0.25), var(--colour-secondary) calc(var(--bsu-xs) * 0.25), var(--colour-secondary) calc(var(--bsu-xs) * 0.75), transparent calc(var(--bsu-xs) * 0.75));
  }
}

.BespokeMaterials__material__link {
  width: 100%;
  transform: rotate(-45deg);
  overflow: hidden;
  display: block;
  position: absolute;
  top: 14.8%;
  left: 0;

  & .Wrap {
    padding: 0;
  }

  & .PaddedImage img {
    transform: rotate(45deg) translateX(-50%);
    width: 200%;
    height: 200%;
    max-width: none;
    max-height: none;
  }
}

.BespokeMaterials__material__pad {
  width: 100%;
  padding-bottom: 141.42%;
}

.BespokeMaterials__material {
  position: relative;
  z-index: 5;
  top: 0;
  flex-basis: 20%;
  margin: 0;
}

/* .BespokeMaterials__material:nth-of-type(2n + 1) {
  transform: translateY(calc(var(--bsu) * -1));
}

.BespokeMaterials__material:nth-of-type(2n + 2) {
  transform: translateY(var(--bsu));
} */


@media (--column-break) {

  .BespokeMaterials__materials {
    padding: 0;
    overflow: hidden;

    & .SylSection__column--left {
      padding: var(--section-padding) 0;
    }

    & .SylSection__columns {
      padding-right: 0;
      align-items: center;
    }

    & .BespokeMaterials__materials__padder {
      /* padding bottom set inline */
      width: 100%;
      display: none;
    }

    & .SylSection__column--right .HorizontalReel,
    & .SylSection__column--right .HorizontalReel:not(.HorizontalReel--constant) {
      position: relative;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      overflow: visible;
      padding: 0;
    }
  }

  .BespokeMaterials__material {
    flex-basis: 33%;
  }

  .BespokeMaterials__material:nth-of-type(3n + 1) {
    transform: translateY(-20%) translateX(168%);
    margin-bottom: 5%;
  }

  .BespokeMaterials__material:nth-of-type(3n + 2) {
    transform: translateY(30%) translateX(-10.3%);
    margin-top: 2.5%;
  }

  .BespokeMaterials__material:nth-of-type(3n + 3) {
    /* right: calc(var(--diamond-width) * 0.5 * -1); */
    transform: translateY(30%) translateX(46.2%);
    margin-top: 2.5%;
  }
}
