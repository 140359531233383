:root {
  /** primary colours
   *  ----------------------------------------------------------------------------------------------
  **/
  --colour-primary: #1a1a1a;
  --colour-secondary: #d5d2bf;
  --colour-highlight: #aaa693;
  --colour-subtle: #4e6359;
  --colour-footer: #2e3833; /* required for contrast on background-subtle */
  --colour-subtler: #d5d2bf;

  /** inverted colours
   *  ----------------------------------------------------------------------------------------------
  **/
  --colour-invert: #f4f0ed;
  --colour-invert-subtle: #dbd4d1;

  /** backgrounds
   *  ----------------------------------------------------------------------------------------------
  **/
  --colour-background-body: #f4f0ed;
  --colour-background-subtle: #e6e6e6;
  --colour-background-secondary: #dbd4d1;
  --colour-background-pop: #993434;

  /** utility
   *  ----------------------------------------------------------------------------------------------
  **/
  --colour-error: #993434;
}
