/** Header Text
 *  ------------------------------------------------------------------------------------------------
 *  consistent default spacing, sizing and alignment for pre/post/header text!
 *  we globally override header text styles at smaller sizes here too
**/

.HeaderText {
  text-align: center;

  & p {
    /* larger font size for pre/post headers */
    font-size: var(--s1);
  }
}

.HeaderText__header {
  line-height: 1.3;
}

@media (--column-break) {

  .HeaderText--align-right {
    text-align: right;
  }

  .HeaderText--align-left {
    text-align: left;
  }
}
