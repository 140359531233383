/** Styles for the kitchen page that aren't component specific.
 *  ------------------------------------------------------------------------------------------------
**/

.KitchenMaterials__materials {
  padding: 0;
  text-align: center;

  & li {
    display: block;
    font-size: var(--s2);
  }

  & a {
    display: block;
    color: var(--colour-invert);

    /* padding: var(--bsu-xs); */

    &:hover,
    &:focus {
      color: var(--colour-secondary);
    }
  }
}

.KitchenMaterials__HorizontalReel {

  & .HorizontalReel__ignore-me {
    position: relative;
  }

  & .KitchenMaterials__HorizontalReel__material-title {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 0.5);
    transition: all var(--time-transition) var(--ease-default);
    transition-property: background-color;
  }

  & a {
    color: var(--colour-invert);
    font-size: var(--s1);

    &:hover,
    &:focus {

      & .KitchenMaterials__HorizontalReel__material-title {
        background-color: rgb(0 0 0 / 0.2);
      }
    }
  }
}

.KitchenMaterials__HorizontalReel > .InlineImage .PaddedImage img,
.KitchenMaterials__HorizontalReel .HorizontalReel__ignore-me > .InlineImage .PaddedImage img {
  max-width: 90vw;
  object-fit: cover;
}
