/** Styles for the Style pages that aren't enough for individual components
 *  ------------------------------------------------------------------------------------------------
**/

/** Bigger headers
 *  ------------------------------------------------------------------------------------------------
**/

@media (--edge-to-edge-ends) {

  .site-main--style > .PageHeader {
    min-height: 60vh; /* taller than normal SylSections */
  }
}


/** Big picture image to edge + line
 *  ------------------------------------------------------------------------------------------------
 *  there's a nice little line that connects the image above to the text below.
 *  top image also butts right up to the right of the page
 *  lovely.
 *  -
 *  We only apply this when two columns are used.
**/

@media (--column-break) {

  .StyleBigPicture {
    overflow: hidden;

    /* & .PaddedImage {
      background-color: var(--colour-background-body);
    } */
  }

  .StyleBigPicture__one {
    padding-bottom: var(--bsu-l);
    position: relative;
    z-index: 2;

    & .SylSection__columns {
      padding-right: 0;
    }

    & .SylSection__column--right .Wrap {
      padding-right: 0;
    }

    & .SylSection__content {
      position: relative;
      z-index: 2;
    }

    &::before {
      content: ' ';
      position: absolute;
      z-index: 1;
      height: 50%;
      width: 100%;
      background: var(--colour-background-body);
      top: 0;
      left: 0;
    }
  }

  .StyleBigPicture__two {
    position: relative;
    z-index: 1;

    & .RichText {
      position: relative;
      background: var(--colour-background-body);

      & .Wrap {
        position: relative;
        z-index: 2;
        background: var(--colour-background-body);
        padding-top: var(--bsu);
      }

      &::after {
        content: ' ';
        position: absolute;
        z-index: 1;
        height: 2000px; /* this could be computed probably... */
        left: 50%;
        bottom: 0;
        width: 2px;
        margin-left: -1px;
        background: var(--colour-subtler);
      }
    }
  }
}


/** Details - align image bottoms and draw lines to text!
 *  ------------------------------------------------------------------------------------------------
**/
/* .StyleDetails__primary {
  padding-top: 0;
} */

.StyleDetails .InlineImage {
  position: relative;
  z-index: 2;
}

.StyleDetails .RichText {
  position: relative;
  padding-bottom: var(--bsu-xs);
  z-index: 1;

  &::after {
    content: ' ';
    position: absolute;
    z-index: 1;
    height: var(--bsu-l);
    left: 50%;
    bottom: 100%;
    width: 2px;
    transform: translateX(-1px);
    background: var(--colour-subtler);
    opacity: 0.5;
  }
}

.StyleDetails .SylSection__column--left .HeaderText + .RichText {
  padding-bottom: 0;

  &::after {
    content: none;
  }
}

@media (--edge-to-edge-ends) {

  .StyleDetails__primary {
    padding-top: var(--bsu-l);
  }
}


@media (--column-break) {
  /* flip the order */
  .StyleDetails .SylSection__column--left {
    order: 2;
  }

  .StyleDetails .SylSection__column--right {
    order: 1;
  }

  /** right side positioning
   *  ----------------------------------------------------------------------------------------------
  **/
  .StyleDetails__primary__flex-columns {
    align-items: stretch;

    & > .SylSection__column--left {
      display: flex;
      flex-direction: column;

      & .InlineImage {
        width: 100%;
        flex: 1;
        position: relative;
        margin-left: var(--bsu);
        margin-right: var(--bsu);
      }

      & .PaddedImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        /* background: transparent !important; */
      }

      & .PaddedImage__padder {
        display: none;
      }

      & img {
        object-fit: cover;
      }
    }
  }

  /** left image positioning
   *  ----------------------------------------------------------------------------------------------
  **/
  .StyleDetails__primary__flex-columns .SylSection__column--right {
    display: flex;
    flex-wrap: wrap;

    & .StyleDetails__content-group {
      flex: 1 0 50%;
    }

    & .StyleDetails__content-group:first-child {
      flex: 1 0 100%;
    }

    & .StyleDetails__content-group:nth-child(2) .Wrap {
      padding-right: calc(var(--bsu) * 0.5);
    }

    & .StyleDetails__content-group:nth-child(3) .Wrap {
      padding-left: calc(var(--bsu) * 0.5);
    }

    & .StyleDetails__content-group:nth-child(3) .RichText::after {
      left: calc(var(--bsu) * 0.5);
    }
  }

  /** lines
   *  ----------------------------------------------------------------------------------------------
  **/
  .StyleDetails .RichText {
    padding-left: var(--bsu-xs);
    padding-bottom: 0;
    z-index: 1;

    &::after {
      height: calc(100% + (var(--bsu-l))); /* this could be computed probably... */
      left: var(--bsu);
      bottom: 0;
      transform: none;
      opacity: 1;
    }
  }

  .StyleDetails .SylSection__column--left .HeaderText + .RichText {
    padding-left: 0;
  }
}
