/** FooterPartners
 *  ------------------------------------------------------------------------------------------------
**/

.FooterPartners {
  padding: var(--bsu);
  background-color: var(--colour-background-subtle);

  & ul {
    padding: 0;
  }
}

.FooterPartners__partner,
.FooterPartners__partner a {
  display: block;
}

.FooterPartners__partner a {
  transition: all var(--ease-default) var(--time-transition);
  transition-property: opacity;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}
