/** Introduction - unique layout for image columns
 *  ------------------------------------------------------------------------------------------------
**/
.BespokeIntroduction__one {

  & .SylSection__column--right {
    margin-top: var(--bsu-l);
  }
}

@media (--column-break) {

  .BespokeIntroduction__one {
    padding-bottom: var(--bsu);

    & .SylSection__column--right {
      margin-top: 0;
    }
  }

  .BespokeIntroduction__two {
    overflow: hidden;
  }

  .BespokeIntroduction__two .HorizontalReel,
  .BespokeIntroduction__two .HorizontalReel:not(.HorizontalReel--constant) {
    overflow: visible;
    display: flex;
    margin: 0 auto;
    max-width: var(--layout-columns);
    max-width: calc(var(--layout-columns) + calc(var(--bsu) * 2));

    & .InlineImage,
    & .BespokeIntroduction__two__vertical {
      flex-shrink: 0;
    }

    & .BespokeIntroduction__two__vertical {
      display: block;
    }

    & > *:nth-child(1),
    & > *:nth-child(2),
    & > *:nth-child(4) {
      flex-basis: 25%;
    }

    & > *:nth-child(3) {
      flex-basis: 35%;
    }

    & .InlineImage .Wrap {
      padding: 0 var(--bsu) 0 0;
    }

    & > .InlineImage:last-child {

      & .Wrap {
        padding: 0;
      }
    }
  }

  .BespokeIntroduction__two__vertical {
    padding: 0 0 0 var(--bsu);

    & > *:nth-child(1) {
      margin-right: var(--bsu);
    }

    & > *:nth-child(2) {
      margin-left: var(--bsu);
      margin-top: var(--bsu);
    }
  }
}
