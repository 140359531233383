/** GenericPopup
 *  ------------------------------------------------------------------------------------------------
**/

.js .GenericPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-overlay);
  overflow: scroll;

  /* display: flex; */
  display: none; /* until js enables it... */

  &.js--popup-active {
    display: block;
  }

  /*
    the container provides a flex based container that is a minimum of 100% tall.
    we need this to be a separate node because the root popup has a fixed 100% height which
    causes large flex children to overflow over the top etc.
  */
  & .GenericPopup__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 100%;
    position: relative;
    padding: var(--bsu-l) 0;
  }

  & .GenericPopup__wrap {
    transition:
      opacity var(--time-transition) var(--ease-default),
      transform var(--time-transition) var(--ease-default);
    opacity: 0;
    transform: scale(0.9);
  }

  &.js--popup-active-trans-in .GenericPopup__wrap {
    opacity: 1;
    transform: scale(1);
  }

  & .GenericPopup__back-close {
    /* background: rgba(0, 0, 0, 0.8); */
    background: var(--colour-subtler);
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    outline: 0;
    z-index: 1;
    transition: opacity var(--time-transition) var(--ease-default);
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  /* we need to position content outside this area */
  & .SylSection__content {
    position: static;
  }

  & .SylSection__column--left {
    display: none;
  }

  & .SylSection__column--right {
    flex-basis: 100%;
    position: relative;
    z-index: 2;
  }

  & .CloseButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--bsu);
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    z-index: var(--z-section-nav);

    & .SvgWrap {
      width: var(--s2);
    }
  }
}

/* two columns at large sizes */

@media (--column-break) {

  .js .GenericPopup {

    & .SylSection__column--left {
      width: 50%;
      flex: 0 0 auto;
      display: block;
      min-height: 1px;

      & .PaddedImage {
        position: absolute;
        width: 45%;
        top: 0;
        left: 0;
        height: 100%;
        overflow: hidden;
        z-index: 1;
      }

      & img {
        height: auto;
        top: 50%;
        transform: translateY(-50%);

        @supports (object-fit: cover) {
          top: 0;
          transform: none;
          object-fit: cover;
          height: 100%;
        }
      }
    }

    & .SylSection__column--right {
      width: 50%;
      flex: 0 0 auto;
    }
  }
}

.GenericPopup__wrap {
  /* full width if there's no js... */
  max-width: var(--layout-max);
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: 2;
}

.no-js .GenericPopup .js--popup-close {
  display: none;
}

.js .GenericPopup__wrap {
  width: 90%;
  max-width: var(--layout-content);
  background: var(--colour-subtler);
  padding: var(--bsu-s);
}

.GenericPopup__content {
  background: var(--colour-background-body);
}
