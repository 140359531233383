/** SiteFooter
 *  ------------------------------------------------------------------------------------------------
**/

.SiteFooter {
  /* the wrap inside already has left/right padding */
  padding: var(--bsu-l) 0;
  color: var(--colour-footer);
  background-color: var(--colour-background-subtle);
  text-align: center;
  overflow: hidden;

  & svg path {
    fill: var(--colour-footer);
    transition: fill var(--time-transition) var(--ease-default);
  }

  /** general list styling for nav and social icons
   *  ----------------------------------------------------------------------------------------------
  **/
  & .nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }

  & .nav li {
    display: block;
    text-transform: uppercase;
  }

  & .nav li a {
    display: inline-block;
    vertical-align: top;
    padding: var(--bsu);
  }

  & a {
    color: inherit;

    &:hover,
    &:focus {
      color: var(--colour-subtle);

      & svg path {
        fill: var(--colour-subtle);
      }
    }
  }
}


/** Footer logo
 *  ------------------------------------------------------------------------------------------------
**/
.SiteFooter__logo {

  & a {
    display: inline-block;
    vertical-align: top;
  }

  & .Logo > .SvgWrap,
  & .Logo > img,
  & .LogoFoot > .SvgWrap,
  & .LogoFoot > img {
    width: 200px; /* TODO: should we let CMS user decide this? */
    max-width: 100%;
  }
}


/** Footer nav
 *  ------------------------------------------------------------------------------------------------
**/
.SiteFooter__nav {
  display: none;

  & .nav .SiteHeader__nav-close-container {
    display: none;
  }
}

@media (--edge-to-edge-ends) {

  .SiteFooter__nav {
    display: block;
  }
}

.SiteFooter__nav,
.SiteFooter__sub-nav {

  & li {

    &::after {
      content: '|';
      display: inline-block;
      vertical-align: top;
      padding: var(--bsu) 0;
    }

    &:last-child::after {
      content: none;
    }
  }
}


/** Social icons
 *  ------------------------------------------------------------------------------------------------
**/
.SiteFooter__social {
  --gap: var(--bsu-l);
  --flow-space: var(--bsu-l);

  & ul {
    justify-content: center;
  }

  & li {
    display: block;
  }
}

.SiteFooter__social .SvgWrap {
  width: var(--s1);
}

.SiteFooter__social li a {
  padding: 0;
}

@media (--edge-to-edge-ends) {

  .SiteFooter__social {
    --flow-space: var(--bsu);
  }
}


/** Footer back to top link
 *  ------------------------------------------------------------------------------------------------
**/
.SiteFooter__back-to-top.CTA {
  --flow-space: var(--bsu-l);

  width: 150%;
  margin-left: -25%;

  & .CTA__a {
    background-color: var(--colour-footer);
    font-family: var(--ff-body);
    font-weight: normal;
    color: var(--colour-invert);
    font-size: var(--s-1);

    &:hover,
    &:focus {
      background-color: var(--colour-subtle);
    }
  }
}


/** Footer sub nav
 *  ------------------------------------------------------------------------------------------------
**/
.SiteFooter__sub-nav {
  font-size: var(--s-1);
  margin-top: var(--bsu-s);

  & .nav li::after {
    padding: 0;
  }

  & .nav li a {
    padding: 0 var(--bsu-xs);
    text-transform: none;
  }
}
