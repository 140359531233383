/** .PaddedImage
 *  ------------------------------------------------------------------------------------------------
 *  making images take the correct space even when unloaded
**/

.PaddedImage {
  position: relative;

  & img {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &.js--wants-intersection {
    /* background-image:
      radial-gradient(currentColor 10%, transparent 10%),
      radial-gradient(currentColor 10%, transparent 10%);
    background-position: 12px 12px, -12px -12px;
    background-size: 48px 48px; */

    background-color: currentColor;
  }
}

.js .PaddedImage.js--wants-intersection img {
  opacity: 0;
  transition: opacity var(--time-transition) var(--time-transition-fast) ease-out;
}

.js .PaddedImage.js--has-intersected img {
  opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
  /* if you have specifically opted out of animation we don't want these to just pop in */
  .js .PaddedImage.js--wants-intersection img {
    opacity: 1;
  }
}

.InlineImage {
  /* when we are edge to edge we pad above by a little extra... */
  --flow-space: var(--bsu-l);
}

.InlineImage .Wrap {
  padding: 0;
}

/* when we are edge to edge we want images immediately after CTAs to collapse their top margin */
/* images that follow images on edge-to-edge should also be seamless */
.u-flows > .InlineImage + .InlineImage,
.u-flows > .CTA + .InlineImage {
  --flow-space: 0;
}


@media (--edge-to-edge-ends) {

  .u-flows > .InlineImage + .InlineImage,
  .u-flows > .CTA + .InlineImage,
  .InlineImage {
    --flow-space: var(--bsu);
  }

  .InlineImage .Wrap {
    padding: 0 var(--bsu);
  }
}

/* if the ratio was forced we want the image to cover the available space where possible */
.PaddedImage--forced-ratio {
  overflow: hidden;

  & img {
    top: 50%;
    left: 50%;
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    transform: translateY(-50%) translateX(-50%);

    @supports (object-fit: cover) {
      top: 0;
      left: 0;
      transform: none;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

.PaddedImage--forced-ratio--contain {

  & img {

    @supports (object-fit: contain) {
      object-fit: contain;
    }
  }
}
