/** Find Us section of the contact page
 *  ------------------------------------------------------------------------------------------------
**/

.ContactFindUs__map {
  position: relative;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ContactFindUs__map__pad {
  width: 100%;
  padding-bottom: 100%;
}
