/** default selection colours
 *  ------------------------------------------------------------------------------------------------
 *  ::-moz-selection fails if included in a group, hence each selector has it's own rules...
**/
::-moz-selection {
  background: var(--colour-secondary);
  color: var(--colour-invert);
  text-shadow: none;
}

::selection {
  background: var(--colour-secondary);
  color: var(--colour-invert);
  text-shadow: none;
}

a::-moz-selection {
  background: var(--colour-secondary);
  color: var(--colour-primary);
  text-shadow: 1px 1px 2px rgb(0 0 0 / 0.3);
}

a::selection {
  background: var(--colour-secondary);
  color: var(--colour-primary);
  text-shadow: 1px 1px 2px rgb(0 0 0 / 0.3);
}
