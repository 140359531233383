/** Styles for the Bespoke page that don't warrant their own components
 *  ------------------------------------------------------------------------------------------------
**/

/** Skinny headers
 *  ------------------------------------------------------------------------------------------------
**/
.BespokeMaterials__header,
.BespokeFittings__header,
.BespokeAppliances__header {
  min-height: 0;
}


/** Overlapping images
 *  ------------------------------------------------------------------------------------------------
**/
.BespokeAppliances__appliances .SylSection__column--left {

  & .InlineImage:nth-child(1) {
    padding-right: 20%;
  }

  & .InlineImage:nth-child(2) {
    position: relative;
    margin-top: -40%;
    padding-left: 50%;
  }
}

@media (--colunm-break) {

  .BespokeAppliances__appliances .SylSection__column--left {

    & .InlineImage:nth-child(2) {
      margin-top: -15%;
    }
  }
}
